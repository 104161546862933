import { useNavigate } from "react-router-dom";
import "./NewSupButton.css"
function NewSupButton() {

    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/newsup')
    }


    return <div className="NewSupButton" onClick={navigateTo}>New Supervisor Account</div>
}

export default NewSupButton