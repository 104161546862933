import "./App.css";

import LoginSection from "./Components/LoginPage";
import Heading from "./Components/HeadingText";
import MainText from "./Components/MainText";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NoPage from "./Components/NoPage";
import ForgotPass from "./Components/ForgotPass";
import FormLogin from "./Components/FormLogin";
import Content from "./Components/Content";
import NewUser from "./Components/NewUser";
import NewSup from "./Components/NewSup";
import FormContent from "./Components/AppraisalForm/FormContent";
import Data from "./Components/AppraisalForm/Data";
import FormControl from "./Components/AppraisalForm/FormControl";
import Base from "./Components/Base";

function App() {
  return (
    <div className="App ">
      <Heading />
      <MainText />
      <BrowserRouter>
        <Routes>
          
          <Route path="/" element={<LoginSection />}>
            <Route path="/" element={<Base />} />
            <Route path="login" element={<FormLogin />} />
            <Route path="passwordReset" element={<ForgotPass />} />
          </Route>

          <Route path="/content" element={<Content />} />
          <Route path="/newUser" element={<NewUser />} />
          <Route path="/newsup" element={<NewSup />} />

          <Route path="/form" element={<FormContent flag={false} />} />
          <Route path="/editForm" element={<FormControl />} />
          <Route path="/data" element={<Data />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
