import "./Heading.css"
function Heading() {
    return (
        <div className="heading text-5xl pt-5 pb-5 sm:text-6xl md:text-7xl ">
            <div className="b1">B</div>
            <div className="amp">&</div>
            <div className="b2">B</div>
        </div>
    )
}

export default Heading;