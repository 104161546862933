import "./LoginPage.css"
import React from "react";
import { Outlet, useNavigate } from 'react-router-dom';

function LoginSection() {

    return (
        <div className="login h-auto">
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <Outlet />
        </div>
    );
}
export default LoginSection;