
import { useState } from "react"
import "./FormControl.css"
import { doc,  getFirestore, setDoc } from "firebase/firestore";
import app from "../../firebaseUtils/firebaseUtils";
import InsertBtn from "../Btns/InsertBtn";
import { useNavigate } from "react-router-dom";
import Comments from "../Comments"; 

function FormControl() {

    const navigate = useNavigate();
    const dataD = JSON.parse(localStorage.getItem("dataD"));

    const db = getFirestore(app);
    const [a_1_remark, set_a_1_remark] = useState();
    const [a_1_rating, set_a_1_rating] = useState();

    const [a_2_remark, set_a_2_remark] = useState();
    const [a_2_rating, set_a_2_rating] = useState();

    const [a_3_remark, set_a_3_remark] = useState();
    const [a_3_rating, set_a_3_rating] = useState();

    const [b_1_remark, set_b_1_remark] = useState();
    const [b_1_rating, set_b_1_rating] = useState();

    const [b_2_remark, set_b_2_remark] = useState();
    const [b_2_rating, set_b_2_rating] = useState();

    const [b_3_remark, set_b_3_remark] = useState();
    const [b_3_rating, set_b_3_rating] = useState();

    const [c_1_remark, set_c_1_remark] = useState();
    const [c_1_rating, set_c_1_rating] = useState();

    const [c_2_remark, set_c_2_remark] = useState();
    const [c_2_rating, set_c_2_rating] = useState();

    const [c_3_remark, set_c_3_remark] = useState();
    const [c_3_rating, set_c_3_rating] = useState();

    const [c_4_remark, set_c_4_remark] = useState();
    const [c_4_rating, set_c_4_rating] = useState();

    const [c_5_remark, set_c_5_remark] = useState();
    const [c_5_rating, set_c_5_rating] = useState();

    const [d_1_remark, set_d_1_remark] = useState();
    const [d_1_rating, set_d_1_rating] = useState();

    const [d_2_remark, set_d_2_remark] = useState();
    const [d_2_rating, set_d_2_rating] = useState();

    const [e_1_remark, set_e_1_remark] = useState();
    const [e_1_rating, set_e_1_rating] = useState();

    const [e_2_remark, set_e_2_remark] = useState();
    const [e_2_rating, set_e_2_rating] = useState();

    const [f_1_remark, set_f_1_remark] = useState();
    const [f_1_rating, set_f_1_rating] = useState();

    const [f_2_remark, set_f_2_remark] = useState();
    const [f_2_rating, set_f_2_rating] = useState();

    const [f_3_remark, set_f_3_remark] = useState();
    const [f_3_rating, set_f_3_rating] = useState();

    const [g_1_remark, set_g_1_remark] = useState();
    const [g_1_rating, set_g_1_rating] = useState();

    const [g_2_remark, set_g_2_remark] = useState();
    const [g_2_rating, set_g_2_rating] = useState();

    const date = Date().toLocaleString();
    const emp = localStorage.getItem("i");   //for which employee for which the data has been entered
    // const mils = String(Date.now());
    // const newID = emp + "_" + mils;
    const current = localStorage.getItem("CurrentEdit");

    // useEffect(() => dataE);
    // console.log(dataD);
    const [EditBtnFlag, setEditBtnFlag] = useState(false);
    const editFlagOn = () => {
        setEditBtnFlag(true);
    }
    const addData = async () => {
        const data = await setDoc(doc(db, "apprData", current), {
            DateCreated: date,
            a: {
                a_1: {
                    rating: a_1_rating,
                    remark: a_1_remark
                },
                a_2: {
                    rating: a_2_rating,
                    remark: a_2_remark
                },
                a_3: {
                    rating: a_3_rating,
                    remark: a_3_remark
                }
            },
            b: {
                b_1: {
                    rating: b_1_rating,
                    remark: b_1_remark
                },
                b_2: {
                    rating: b_2_rating,
                    remark: b_2_remark
                },
                b_3: {
                    rating: b_3_rating,
                    remark: b_3_remark
                }
            },
            c: {
                c_1: {
                    rating: c_1_rating,
                    remark: c_1_remark
                },
                c_2: {
                    rating: c_2_rating,
                    remark: c_2_remark
                },
                c_3: {
                    rating: c_3_rating,
                    remark: c_3_remark
                },
                c_4: {
                    rating: c_4_rating,
                    remark: c_4_remark
                },
                c_5: {
                    rating: c_5_rating,
                    remark: c_5_remark
                }
            },
            d: {
                d_1: {
                    rating: d_1_rating,
                    remark: d_1_remark
                },
                d_2: {
                    rating: d_2_rating,
                    remark: d_2_remark
                },
            },
            e: {
                e_1: {
                    rating: e_1_rating,
                    remark: e_1_remark
                },
                e_2: {
                    rating: e_2_rating,
                    remark: e_2_remark
                },
            },
            f: {
                f_1: {
                    rating: f_1_rating,
                    remark: f_1_remark
                },
                f_2: {
                    rating: f_2_rating,
                    remark: f_2_remark
                },
                f_3: {
                    rating: f_3_rating,
                    remark: f_3_remark
                },
            },
            g: {
                g_1: {
                    rating: g_1_rating,
                    remark: g_1_remark
                },
                g_2: {
                    rating: g_2_rating,
                    remark: g_2_remark
                },
            },
        });
        navigate("/data");
    }

    const conversion = (e) => {
        if (e == 0) { return "Null" }
        else if (e == 1) { return "Unacceptable" }
        else if (e == 2) {
            return "Needs Improvement"
        }
        else if (e == 3) {
            return "Meets Expectation"
        }
        else if (e == 4) {
            return "Exceeds Expectation"
        }
    }
    return (
        <div className="app_form">
            <div className="textSecond">
                Appraisal Form
            </div>
            <div className="dataTable">
                <table>
                    <tbody>
                        <tr><td className="mainTable headsTable" colSpan={2}>Performance Category</td>
                            <td className="mainTable headsTable">Ratings</td>
                            <td className="mainTable headsTable" >Remarks</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable firstCol" rowSpan={3}>Quality of Work</td>
                            <td className="mainTable secondCol" >Work is Completed accurately</td>
                            <td className="mainTable thirdCol">{conversion(dataD.a.a_1.rating)}</td>
                            <td className="mainTable fourthCol">{dataD.a.a_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Works effeciently within deadlines</td>
                            <td className="mainTable">{conversion(dataD.a.a_2.rating)}</td>
                            <td className="mainTable">{dataD.a.a_2.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Requires minimum supervision	</td>
                            <td className="mainTable">{conversion(dataD.a.a_3.rating)}</td>
                            <td className="mainTable">{dataD.a.a_3.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={3}>Reliability / Dependability	</td>
                            <td className="mainTable" >Manages time effectively	</td>
                            <td className="mainTable">{conversion(dataD.b.b_1.rating)}</td>
                            <td className="mainTable">{dataD.b.b_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Manages workload effectively	</td>
                            <td className="mainTable">{conversion(dataD.b.b_2.rating)}</td>
                            <td className="mainTable">{dataD.b.b_2.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Consistently performs at high level</td>
                            <td className="mainTable">{conversion(dataD.b.b_3.rating)}</td>
                            <td className="mainTable">{dataD.b.b_3.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={5}>Initiative & Flexibility</td>
                            <td className="mainTable" >Demonstrates initiative		</td>
                            <td className="mainTable">{conversion(dataD.c.c_1.rating)}</td>
                            <td className="mainTable">{dataD.c.c_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Seeks out additional responsibility	</td>
                            <td className="mainTable">{conversion(dataD.c.c_2.rating)}</td>
                            <td className="mainTable">{dataD.c.c_2.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Identifies problems and solutions	</td>
                            <td className="mainTable">{conversion(dataD.c.c_3.rating)}</td>
                            <td className="mainTable">{dataD.c.c_3.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Enjoys new challenges		</td>
                            <td className="mainTable">{conversion(dataD.c.c_4.rating)}</td>
                            <td className="mainTable">{dataD.c.c_4.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Adjusts to unexpected changes	</td>
                            <td className="mainTable">{conversion(dataD.c.c_5.rating)}</td>
                            <td className="mainTable">{dataD.c.c_5.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={3}>Reliability / Dependability	</td>
                            <td className="mainTable" >Manages time effectively	</td>
                            <td className="mainTable">{conversion(dataD.b.b_1.rating)}</td>
                            <td className="mainTable">{dataD.b.b_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Manages workload effectively	</td>
                            <td className="mainTable">{conversion(dataD.b.b_2.rating)}</td>
                            <td className="mainTable">{dataD.b.b_2.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Consistently performs at high level</td>
                            <td className="mainTable">{conversion(dataD.b.b_3.rating)}</td>
                            <td className="mainTable">{dataD.b.b_3.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={2}>Judgement & Decision making</td>
                            <td className="mainTable" >Makes thoughtful & <br />
                                well-reasoned judgement		</td>
                            <td className="mainTable">{conversion(dataD.d.d_1.rating)}</td>
                            <td className="mainTable">{dataD.d.d_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Resourceful and creative<br />
                                in problem solving</td>
                            <td className="mainTable">{conversion(dataD.d.d_2.rating)}</td>
                            <td className="mainTable">{dataD.d.d_2.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={2}>Cooperation & team work	</td>
                            <td className="mainTable" >Respectful of colleagues			</td>
                            <td className="mainTable">{conversion(dataD.e.e_1.rating)}</td>
                            <td className="mainTable">{dataD.e.e_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Makes valuable contributions <br/>
                                to achieve goals as a team<br />
                                in problem solving</td>
                            <td className="mainTable">{conversion(dataD.e.e_2.rating)}</td>
                            <td className="mainTable">{dataD.e.e_2.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={3}>Communication	</td>
                            <td className="mainTable" >Listens and comprehend well			</td>
                            <td className="mainTable">{conversion(dataD.f.f_1.rating)}</td>
                            <td className="mainTable">{dataD.f.f_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Clear, organised and <br/>
                                effective oral communication</td>
                            <td className="mainTable">{conversion(dataD.f.f_2.rating)}</td>
                            <td className="mainTable">{dataD.f.f_2.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Clear & organised<br/>
                                in written communication</td>
                            <td className="mainTable">{conversion(dataD.f.f_3.rating)}</td>
                            <td className="mainTable">{dataD.f.f_3.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={2}>Attendance & Punctuality		</td>
                            <td className="mainTable" >Reports for work on time	</td>
                            <td className="mainTable">{conversion(dataD.g.g_1.rating)}</td>
                            <td className="mainTable">{dataD.g.g_1.remark}</td>
                        </tr>
                        <tr>
                            <td className="mainTable">Provides advance notice<br/>
                                of need for absence</td>
                            <td className="mainTable">{conversion(dataD.g.g_2.rating)}</td>
                            <td className="mainTable">{dataD.g.g_2.remark}</td>
                        </tr>
                    </tbody>
                </table>
                    <div className="btn"><InsertBtn AddText="Edit Data" action={editFlagOn} /></div>
                
            </div>

            {EditBtnFlag && <div className="main_table">
                <table className="editTable">
                    <tbody>

                        <tr>
                            <td className="mainTable headsTable" colSpan={2}>Performance Category</td>
                            <td className="mainTable headsTable">Ratings</td>
                            <td className="mainTable headsTable" >Remarks</td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable firstCol" rowSpan={3}>Quality of Work</td>
                            <td className="mainTable secondCol" >Work is Completed accurately</td>
                            <td className="mainTable thirdCol">
                                <select onChange={(e) => { set_a_1_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable fourthCol" onChange={(e) => { set_a_1_remark(e.target.value) }}><input type="text" className="remark"
                                onChange={(e) => { set_a_1_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Works effeciently within deadlines</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_a_2_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><textarea cols="5" className="remark" onChange={(e) => { set_a_2_remark(e.target.value) }} /></td>
                        </tr>
                        
                        <tr>
                            <td className="mainTable">Requires minimum supervision</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_a_3_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark"
                                onChange={(e) => { set_a_3_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={3}>Reliability / Dependability
                            </td>
                            <td className="mainTable">Manages time effectively
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_b_1_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark"
                                onChange={(e) => { set_b_1_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Manages workload effectively
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_b_2_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_b_2_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Consistently performs at high level</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_b_3_rating(e.target.value) }} >
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark"
                                onChange={(e) => { set_b_3_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={5}>Initiative & Flexibility</td>
                            <td className="mainTable">Demonstrates initiative</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_c_1_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_c_1_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Seeks out additional responsibility
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_c_2_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_c_2_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">ldentifies problems and solutions

                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_c_3_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark"
                                onChange={(e) => { set_c_3_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Enjoys new challenges
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_c_4_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_c_4_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Adjusts to unexpected changes
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_c_5_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark"
                                onChange={(e) => { set_c_5_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={2}>Judgement & Decision making
                            </td>
                            <td className="mainTable">Makes thoughtful &<br /> well-reasoned judgement
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_d_1_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_d_1_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Resourceful and creative<br /> in problem solving</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_d_2_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_d_2_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={2}>Cooperation & team work

                            </td>
                            <td className="mainTable">Respectful of colleagues</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_e_1_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_e_1_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>

                            <td className="mainTable">Makes valuable contributions<br /> to achieve goals as a team
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_e_2_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_e_2_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={3}>Communication
                            </td>
                            <td className="mainTable">Listens and comprehend well</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_f_1_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark"
                                onChange={(e) => { set_f_1_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Clear, organised and <br />effective oral communication</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_f_2_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_f_2_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable">Clear & organised <br />in written communication
                            </td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_f_3_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_f_3_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td className="mainTable headsTable" rowSpan={2}>Attendance & Punctuality</td>
                            <td className="mainTable">Reports for work on time</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_g_1_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable"><input type="text" className="remark" onChange={(e) => { set_g_1_remark(e.target.value) }} /></td>
                        </tr>
                        <tr>

                            <td className="mainTable">Provides advance notice <br />of need for absence</td>
                            <td className="mainTable">
                                <select onChange={(e) => { set_g_2_rating(e.target.value) }}>
                                    <option value="0" defaultChecked>Select Expectation Level</option>
                                    <option value="4">Exceeds Expectation</option>
                                    <option value="3">Meets Expectation</option>
                                    <option value="2">Needs Improvement</option>
                                    <option value="1">Unacceptable</option>
                                </select>
                            </td>
                            <td className="mainTable" ><input onChange={(e) => { set_g_2_remark(e.target.value) }} type="text" className="remark" /></td>
                        </tr>

                        <tr>
                            <td colSpan={4}><InsertBtn action={addData} className="Inbtn" AddText="Edit Form" /></td>
                        </tr>

                    </tbody>
                </table>
            </div>}
            <div className="commentSection">
                <h1 className="headsComments">Feedback</h1>
                <Comments />
            </div>
        </div>
    )
}


export default FormControl