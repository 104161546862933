import app from "../firebaseUtils/firebaseUtils";

import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { getFirestore, getDocs, setDoc, doc, getDoc, arrayUnion, updateDoc } from "firebase/firestore";


import "./NewSup.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RegEmp from "./Btns/RegEmp";

function NewUser() {
    const auth = getAuth();

    // const superviserData = {
    //     SupName: "",
    //     SupID: "",
    //     SupMail: "",
    //     SupPhone:null,
    //     SupDiv:""
    // }

    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/content')
    }

    const [EmpName, setEmpName] = useState("");
    const [EmpID, setEmpID] = useState("");
    const [EmpMail, setEmpMail] = useState("");
    const [EmpPhone, setEmpPhone] = useState(null);
    const [EmpDiv, setEmpDiv] = useState("");
    // const [EmpUID, setEmpUID] = useState("");
    const [EmpSuperID, setEmpSuperID] = useState("");
    const [EmpDesig, setEmpDesig] = useState("");
    const db = getFirestore(app);

    const [docFlag, setDocFlag] = useState(false);

    const addToDoc = async () => {
        try {

            let EmpSuperMail = "";
            let EmpSuperPhone = "";
            console.log(EmpSuperID);
            // const supDoc = query(collection(db, "Superviser"), where("SuperID", "==", EmpSuperID));
            const supDoc = doc(db, "Superviser", EmpSuperID);
            const supSnapD = await getDoc(supDoc);
            if (supSnapD.exists()) {
                let supData = supSnapD.data();
                EmpSuperMail = supData.SuperMail;
                EmpSuperPhone = supData.SuperPhone;
                setDocFlag(true);
            } else {
                console.log("No such document!");
                alert("Enter a Valid Supervisor ID!");
                setDocFlag(false);
            }
            const date = Date().toLocaleString();
            const addData = async () => {
                try {
                    const data = await setDoc(doc(db, "Employee", EmpID), {
                        DateCreated: date,
                        EmpName: EmpName,
                        EmpDiv: EmpDiv,
                        EmpID: EmpID,
                        EmpMail: EmpMail,
                        EmpPhone: EmpPhone,
                        EmpSupID: EmpSuperID,
                        EmpDes: EmpDesig,
                        EmpSupPhone: EmpSuperPhone,
                        EmpSupMail: EmpSuperMail,
                    });
                    const data1 = await setDoc(doc(db, "EmployeeData", EmpID), {
                        Email: EmpMail,
                        EmpID: EmpID,
                        accessFlag: 1,
                    });
                    signIn();
                    const Super = doc(db, "Superviser", EmpSuperID);
                    await updateDoc(Super, {
                        emps: arrayUnion(EmpID)
                    });

                    setDocFlag(true);
                    navigateTo();
                }
                catch (err) {
                    console.log(err)

                }
            }

                addData();
                       // console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
            alert("Some error occured!")
        }
    }
    const creds = JSON.parse(localStorage.getItem("User"));
    const signIn = () => {
        signInWithEmailAndPassword(auth, creds[0], creds[1])
            .then((userCredential) => {
                // Signed in 
                // const user = userCredential.user;
                // const uEmail = user.email;
                // console.log(user.email);
                // alert("Successfully logged in")
                // const user1 = auth.currentUser;
                navigateTo();
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                // const errorMessage = error.message;
                // if (String(errorCode) === "auth/wrong-password") { setFlag(true); }
                // else if (String(errorCode) === "auth/user-not-found") { setCredFlag(true); }
                alert(errorCode);
            });

    }
    const signUp = () => {
        addToDoc()
        if (docFlag) {
            createUserWithEmailAndPassword(auth, EmpMail, EmpMail)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    // console.log(user)
                    const uid = user.uid;
                    // console.log(uid);
                    console.log("Successfully created and account")

                })
                .catch((error) => {
                    const errorCode = error.code;
                    // const errorMessage = error.message;
                    alert(errorCode)
                    // ..
                });
        }
    }

    return (
        <div className="dataForm">
            <div className="textSecond">
                New Employee Account Registration
            </div>
            <div className="table">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label htmlFor="name" className="newSupLabel">Employee Name</label>
                            </td>
                            <td>
                                <input type="text" id="name" className="newSup" required onChange={(e) => { setEmpName(e.target.value) }} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <label htmlFor="name" className="newSupLabel">Supervisor ID</label>
                            </td>
                            <td>
                                <input type="text" id="name" className="newSup" required onChange={(e) => { setEmpSuperID(e.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="ID" className="newSupLabel">Employee ID</label>
                            </td>
                            <td>
                                <input type="text" id="ID" className="newSup" required onChange={(e) => { setEmpID(e.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="email" className="newSupLabel">Email ID </label>
                            </td>
                            <td>
                                <input type="email" id="email" className="newSup" required onChange={(e) => { setEmpMail(e.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="Phone" className="newSupLabel">Phone Number</label>
                            </td>
                            <td>
                                <input type="tel" id='Phone' className="newSup" required onChange={(e) => { setEmpPhone(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="site" className="newSupLabel">Site/Division</label>
                            </td>
                            <td>
                                <input type="text" id='site' className="newSup" required onChange={(e) => { setEmpDiv(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="site" className="newSupLabel">Designation</label>
                            </td>
                            <td>
                                <input type="text" id='desig' className="newSup" required onChange={(e) => { setEmpDesig(e.target.value) }} /></td>
                        </tr>
                    </tbody>
                </table>
                <RegEmp action={signUp} />
            </div>
        </div>
    )
}

export default NewUser;