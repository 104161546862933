import "./Comments.css"
import NewCommentBtn from "./Btns/NewCommentBtn";
import { useState } from "react";

const Comments = () => {

    const [comments, setComments] = useState([
        { type: 'Employee', date: '25/08/23 09:17 ', comment: 'I am unhappy with my review. Please update the third and fifth categories', id: 1},
        { type: 'Supervisor', date: '25/08/23 18:20 ', comment: 'Well, I am willing to change the third one. Please get back to me on any improvements that you can make with regards to the fifth category', id: 2},
        { type: 'Employee', date: '25/08/23 19:54', comment: 'Of course, sir. I have sent an e-mail to you', id: 3},
        { type: 'Supervisor', date: '26/08/23 13:40', comment: 'Changes have been made visible.', id: 4},
        { type: 'Employee', date: '26/08/23 15:28', comment: 'Thank you very much, sir!', id: 5}
    ])

    return (  
        <div>
            <div className="comment">
                {comments.map((cmnt) => (
                    <div className="comment-preview" key = {cmnt.id}>
                        <p className="headView"> { cmnt.type } </p>
                        <p className="dateView"> { cmnt.date }:&nbsp;&nbsp;&nbsp;</p>
                        <p className="textView"> { cmnt.comment }</p>
                    </div>
                ))}
            </div>

            <div>
                <NewCommentBtn />
            </div>
        </div>
    );
}
 
export default Comments;