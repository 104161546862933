import "./SignOutBtn.css"
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function SignOutBtn() {
    
    const auth = getAuth();
    const navigate = useNavigate();
    const signOUT = ()=>signOut(auth).then(() => {
        // Sign-out successful.

            navigate('/login')
    }).catch((error) => {
        // An error happened.
    });

    return (
        <div className="signout" onClick={signOUT}>
            Sign Out
        </div>
    )
}

export default SignOutBtn;