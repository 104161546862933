import "./NewCommentBtn.css"

const NewCommentBtn = () => {

    const handleClick = () => {
        console.log("Clicked!");
    }

    return ( 
        <div className="btn">
            <button className="addComment" onClick={handleClick}> + Add Comment</button>
        </div>
    );
}
 
export default NewCommentBtn;