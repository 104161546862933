import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import app from "../../firebaseUtils/firebaseUtils";
import { useState, useEffect } from "react";
import InsertBtn from "../Btns/InsertBtn"; import { useNavigate } from "react-router-dom";

import "./Data.css"
function Data() {
    const navigate = useNavigate();
    const navigateToForm = () => {
        navigate("/form")
    }
    const navigateToEdit = () => {
        navigate("/editForm")
    }

    let dataD;
    const editRed = async (e) => {
        localStorage.setItem("CurrentEdit", e.currentTarget.id);
        dataD = await getDoc(doc(db, "apprData", e.currentTarget.id));
        dataD = dataD.data();
        // console.log(dataD);
        localStorage.setItem("dataD", JSON.stringify(dataD));
        navigateToEdit();
    }

    const empID = localStorage.getItem("i");
    // console.log(empID);
    const db = getFirestore(app);
    const EmpChecker = (ref) => {
        ref = String(ref);
        const empD = ref.split("_");
        // empD[0]

        if (String(empD[0]) === String(empID)) {
            console.log(empD);
            // console.log("Document Fetched successfully");
            return true
        }
        else {
            // console.log("Wrong Document Fetched or No Doc Found");
            return false;
        }
    }

    useEffect(() => {
        getData();
    }, [db, empID])

    const [empData, setEmpData] = useState({});

    const [docs, setDocs] = useState([]);
    const [docsFlag, setDocsFlag] = useState(false);
    const [load, setLoad] = useState(true);

    const getData = async () => {
        const q = query(collection(db, "apprData"));
        const querySnapshot = await getDocs(q);
        const EmpDoc = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(docs.length);
            if (EmpChecker(doc.id)) {
                const docData = doc.data();
                docData.id = doc.id.split("_")[0];
                docData.uid = doc.id;
                (EmpDoc.push(docData));
                setDocs(EmpDoc);
                // console.log(docs.length);
                setDocsFlag(true);
                setLoad(false);
                // console.log(doc.id, " => ", doc.data());
                return
            }
        });
        setLoad(false);
    };

    return (
        <div className="full">
            <div className="textSecond">
                Employee Data
            </div>
            <div className="loadbtn">
                {/* <InsertBtn AddText="Load Data" action={getData} /> */}
                <InsertBtn AddText="New Entry" action={navigateToForm} />
            </div>
            {
                (docsFlag) && <div className="emptable">
                    <table>
                        <thead>
                            <tr>
                                <td className="empheads">ID</td>
                                <td className="empheads">Date Created</td>
                                <td className="empheads">Time Entered</td>
                            </tr>

                        </thead>
                        <tbody>

                            {docs.map(Doc => {
                                const date = String(Doc.DateCreated).split(" ");
                                // console.log(date);
                                return <tr className="data" id={Doc.uid} key={Doc.uid} onClick={editRed}>
                                    <td className="datas"  >{Doc.id}</td>
                                    <td className="datas" >{date[0] + " " + date[1] + " " + date[2] + " " + date[3]}
                                    </td>
                                    <td className="datas"  >{date[4]}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            }
            {
                (!docsFlag) && (!load) &&
                <div className="alertEmp">
                    No Data Entered for this Employee
                </div>

            }
            {
                (load)  &&
                <div className="loadingScreen">
                    <div className="loadingSpinner"></div>
                </div>

            }
        </div>
    )
}

export default Data;