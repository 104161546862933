import { getAuth, onAuthStateChanged } from "firebase/auth";
import NewSupButton from "./Btns/NewSupBtn";
import NewEmpButton from "./Btns/NewEmpButton";
import "./Content.css";
import { useState } from "react";
import SignOutBtn from "./Btns/SignOutBtn";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import app from "../firebaseUtils/firebaseUtils";
import Employees from "./AppraisalForm/Employees";
import EmployeeAppr from "./AppraisalForm/EmployeeAppr";

function Content() {
    const db = getFirestore(app);

    const [SupFlag, setSupFlag] = useState(false);
    const [EmpFlag, setEmpFlag] = useState(false);
    const [AdminFlag, setAdminFlag] = useState(false);
    const [Uemail, setUemail] = useState("");
    const [loading, setLoading] = useState(true);

    const auth = getAuth();


    onAuthStateChanged(auth, (user) => {
        if (user) {
            // const uid = user.uid;
            setUemail(String(user.email.toLowerCase()));
        } else {
        }
    });

    let accessFlag = 0;
    const getEmpFlag = async() => {
        
        const checkEmp = query(collection(db, "EmployeeData"), where("Email", "==", String(Uemail)));
        
        let checkSnap;
        checkSnap = await getDocs(checkEmp)
        checkSnap.forEach((chkSnp) => {
            
            if (chkSnp.exists()) {
                accessFlag = chkSnp.data().accessFlag;
                console.log(chkSnp.data());

                if (accessFlag === 1) {
                    console.log(chkSnp.data())
                    setEmpFlag(true);
                }
                else if (accessFlag === 2) {
                    console.log(chkSnp.data())
                    setSupFlag(true);
                }
                else if (accessFlag === 3) {
                    console.log(chkSnp.data())
                    setAdminFlag(true);
                }
            }
        })
    }

    getEmpFlag()

    let chkData;
    const empData = [];
    const emps = [];
    const getSupFlag = async () => {

        const checkSup = query(collection(db, "Superviser"), where("SuperMail", "==", String(Uemail)));

        let checkSnap;
        checkSnap = await getDocs(checkSup)
        checkSnap.forEach((chkSnp) => {
            // console.log(chkSnp.data());
            if (chkSnp.exists()) {
                // setFlagSup(true);
                chkData = chkSnp.data().emps;
                // console.log(chkData);
                if (chkData) {
                    chkData.forEach(chD => {
                        emps.push(chD);
                    })
                }
                // else { console.log("No employees registered under this account"); }
            }
            setLoading(false);
        })
        setLoading(false);


        if (SupFlag) {
            if (emps.length > 0) {
                emps.forEach(async emp => {
                    const ref = doc(db, "Employee", emp)
                    const empDoc = await getDoc(ref);
                    empData.push(empDoc.data());
                    // console.log(empData);
                    localStorage.setItem("ourEmp", JSON.stringify(" "));
                    localStorage.setItem("ourEmp", JSON.stringify(empData));
                })
            }
            else {
                localStorage.setItem("ourEmp", JSON.stringify(" "));
                localStorage.setItem("ourEmp", JSON.stringify(empData));

            }
        }
        // setting()
    }
    getSupFlag()

    // let flagAdmin = false;
    // console.log(Uemail)
    //HERE IS THE ADMIN EMAIL
    // if (Uemail === "test1@gmail.com") {
    //     flagAdmin = true;
    // }

    return ( 
        <div>
          {loading && !AdminFlag ? (
            <div className="loadingScreen">
                <div className="loadingSpinner"></div>
            </div>
          ) : (
            <>
              {(AdminFlag || SupFlag) && (
                <div className="full">
                   <div className="textSecond" id="xyz">
                        New Registration <SignOutBtn id="signOutxyz"/>
                    </div>
                    <div className="control_btns">
                        {AdminFlag && <NewSupButton />}
                        <NewEmpButton />
                    </div>
                    
                </div>
              )}
              {SupFlag && <Employees />}
              {EmpFlag && (
                <div>
                    <EmployeeAppr /> 
                </div>
                )}
            </>
          )}
          <button ><a href="#xyz" className="abc">Top</a></button>
        </div>
      );

//     return (
    
//     <div>
//         {loading ? (
//             <div> loading...</div>
//         ) : (
//         <></> 
//         {(flagAdmin || flagSup) &&
//             <div className="full">
//                 <div className="textSecond">
//                     New Registration
//                 </div>
//                 <div className="control_btns">
//                     {flagAdmin && <NewSupButton />}
//                     <NewEmpButton />
//                 </div>
//                 <SignOutBtn />
//             </div>
//         }
//         {
//             flagSup &&
//             <Employees />
//         }
//         {
//             (!flagAdmin && !flagSup) && <div className="full">
//                 <div className="textSecond" >
//                     Employee Account
//                 </div>
//                 <SignOutBtn />
//             </div>
//         }

//     </div>)
//     );
}
export default Content;