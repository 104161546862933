import { useNavigate } from "react-router-dom";
import "./Employees.css";
import SignOutBtn from "../Btns/SignOutBtn"; 
function Employees() {
    const emps = JSON.parse(localStorage.getItem("ourEmp"));
    const lenght = emps.length;
    // console.log(lenght);
    const navigate = useNavigate(); 
    
    const enterRid = (e) => {
        // console.log(e.currentTarget.id);
        localStorage.setItem("i", (e.currentTarget.id));
        navigate("/data");
    }

    //
    return (
        <div className="full">
            <div className="textSecond">
                Employee Data <SignOutBtn id="signOutxyz"/>
            </div>

            {(lenght!==0) && <div className="emptable">
                <table>
                    <thead>
                        <tr>
                            <td className="empheads">ID</td>
                            <td className="empheads">Name</td>
                            <td className="empheads">Supervisor</td>
                            <td className="empheads textShrink">Designation</td>
                        </tr>

                    </thead>
                    
                    <tbody>
                        {emps.map(emp => {
                            return <tr className="data" key={emp.EmpID} id={emp.EmpID} onClick={enterRid} >
                                <td className="datas"  >{emp.EmpID}</td>
                                <td className="datas" >{emp.EmpName}</td>
                                <td className="datas" >{emp.EmpSupID}</td>
                                <td className="datas textShrink" >{emp.EmpDes}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}
            {(lenght === 0) && <div className="alertEmp">
                No Employee Registed under this Account
            </div>}
        </div>
    )
} 

export default Employees;