import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import app from "../firebaseUtils/firebaseUtils";
import { useNavigate } from "react-router-dom";

import "./ForgotPass.css"
function ForgotPass() {

    const auth = getAuth(app);
    const [email, setEmail] = useState("")
    const navigate = useNavigate();

    const resetMail = () => sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
            alert("Mail has been sent on the Email Entered for password Reset");
            navigate('/login')
        })
        .catch((error) => {
            const errorCode = error.code;
            // const errorMessage = error.message;
            alert(errorCode)
            // ..
        });

    return (

        <div>
            <div className="form">
                <div className="textSecond">
                    Reset Password
                </div>

                <div className="formInput">
                    <section id="emailBlock">
                        <label htmlFor="emailInput"> Email
                        <input type={"email"} name="id_input" id="resetEmail" required onChange={(e) => { setEmail(e.target.value) }} /></label>
                    </section>
                </div>

                {/* <table>
                    <tbody>
                        <tr> 
                            <td className="label">
                                <label htmlFor="resetEmail">Email</label>
                            </td>
                            <td>
                                <input type={"email"} name="resetEmail" id="resetEmail" onChange={(e) => { setEmail(e.target.value) }} />
                            </td>
                        </tr>
                    </tbody>
                </table> */}
                <div className="ResetButton" onClick={resetMail}>Reset Password</div>
            </div>
        </div>
    )
}

export default ForgotPass;