import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDCqkOCFz3mc5xmxsjmVCQJBvM18OaBjOU",
  authDomain: "appraisal-bnb.firebaseapp.com",
  projectId: "appraisal-bnb",
  storageBucket: "appraisal-bnb.appspot.com",
  messagingSenderId: "871298204083",
  appId: "1:871298204083:web:7139d2aef07698bde9cbc6",
  databaseURL: "https://appraisal-bnb-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);

// const adminApp = initializeApp(firebaseConfig);
export default app;
