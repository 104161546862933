import "./RegSup.css";
import { useState, useEffect} from "react";

function RegEmp(props) {

    const [isClicked, setIsClicked] = useState(false)
    const handleClick = () => {
        //console.log("Setting to true");
        setIsClicked(true);
        props.action();
    }

    return ( <div className="RegSup" onClick={handleClick}>
        
        { isClicked ? (<p>Registering...</p>) : (<p>Register as Employee</p>)}

        </div> )
}

export default RegEmp