import app from "../firebaseUtils/firebaseUtils";

import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";

import { collection, addDoc, getFirestore, setDoc, doc } from "firebase/firestore";


import "./NewSup.css";
import RegSup from "./Btns/RegSup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function NewSup() {
    const auth = getAuth();

    // const superviserData = {
    //     SupName: "", 
    //     SupID: "",
    //     SupMail: "",
    //     SupPhone:null,
    //     SupDiv:""
    // }

    const [SupName, setSupName] = useState("");
    const [SupID, setSupID] = useState("");
    const [SupMail, setSupMail] = useState("");
    const [SupPhone, setSupPhone] = useState(null);
    const [SupDiv, setSupDiv] = useState("");
    const [SupUID, setSupUID] = useState(null);

    // const [Uemail, setUemail] = useState("");
    // onAuthStateChanged(auth, (user) => {
    //     if (user) {
    //         // User is signed in, see docs for a list of available properties
    //         // https://firebase.google.com/docs/reference/js/auth.user
    //         const uid = user.uid;
    //         // console.log(user);
    //         // ...
    //         setUemail(String(user.email));
    //         // console.log(userData);
    //     } else {
    //         // User is signed out
    //         // ...

    //     }
    // });

    // let flagAdmin = false;
    // console.log(Uemail)

    //HERE IS THE ADMIN EMAIL
    // if (Uemail === "test1@gmail.com") {
    //     flagAdmin = true;
    // }



    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/content')
    }
    // if (flagAdmin === false) {
    //     navigateTo();
    // }
    const db = getFirestore(app);

    const date = Date().toLocaleString();

    const addData = async (SupUID) => {
        try {
            const data = await setDoc(doc(db, "Superviser", SupID), {
                DateCreated: date,
                SuperUID: SupUID,
                SuperName: SupName,
                SuperDiv: SupDiv,
                SuperID: SupID,
                SuperMail: SupMail,
                SuperPhone: SupPhone,
            });
            const data1 = await setDoc(doc(db, "EmployeeData", SupID), {
                Email: SupMail,
                EmpID: SupID,
                accessFlag: 2,
            });
            signIn();
        }
        catch (err) {
            console.log(err)
        }
    }
    const creds = JSON.parse(localStorage.getItem("User"));


    const signIn = () => {
        signInWithEmailAndPassword(auth, creds[0], creds[1])
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const uEmail = user.email;
                // console.log(user.email);
                // alert("Successfully logged in")
                const user1 = auth.currentUser;
                console.log("New supervisor created successfully");
                navigateTo();
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // if (String(errorCode) === "auth/wrong-password") { setFlag(true); }
                // else if (String(errorCode) === "auth/user-not-found") { setCredFlag(true); }
                alert(errorCode);
            });

    }

    const signUp = () => {
        createUserWithEmailAndPassword(auth, SupMail, SupMail)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user)
                // SupUID = user.uid;
                // setSupUID(user.uid);
                const uid = user.uid;
                // console.log(uid);
                // console.log("Successfully created an account")
                // ...

                // addToDoc(uid);
                addData(uid);
            })
            .catch((error) => {
                const errorCode = error.code;
                // const errorMessage = error.message;
                alert(errorCode)
                // ..
            });
    }

    // console.log(SupName,SupDiv,SupID,SupMail,SupPhone)


    return (
        <div className="dataForm">
            <div className="textSecond">
                New Supervisor Account Registration
            </div>
            <div className="table">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label htmlFor="name" className="newSupLabel">Supervisor Name</label>
                            </td>
                            <td>
                                <input type="text" id="name" className="newSup" onChange={(e) => { setSupName(e.target.value)}}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="ID" className="newSupLabel">Employee ID</label>
                            </td>
                            <td>
                                <input type="text" id="ID" className="newSup" onChange={(e) => { setSupID(e.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="email" className="newSupLabel">Email ID </label>
                            </td>
                            <td>
                                <input type="email" id="email" className="newSup" onChange={(e) => { setSupMail(e.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="Phone" className="newSupLabel">Phone Number</label>
                            </td>
                            <td>
                                <input type="text" id='Phone' className="newSup" onChange={(e) => { setSupPhone(e.target.value) }} /></td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="site" className="newSupLabel">Site/Division</label>
                            </td>
                            <td>
                                <input type="text" id='site' className="newSup" onChange={(e) => { setSupDiv(e.target.value) }} /></td>
                        </tr>
                    </tbody>
                </table>
                <RegSup action={signUp} />
            </div>
        </div>
    )
}


export default NewSup;