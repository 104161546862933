import { useEffect } from "react";
import { useNavigate } from "react-router-dom"


function Base() {
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate("login")
    }
    useEffect(navigateTo);
    
return <></>
}

export default Base;