import "./RegSup.css";
import { useState } from "react";
function RegSup(props) {

    const [isClicked, setIsClicked] = useState(false);
    const handleClick = () => {
        console.log(isClicked);
        setIsClicked(true);
        console.log(isClicked);
        props.action();
    }

    return (
        <div className="RegSup" onClick={handleClick}>
            { isClicked ? (<p>Registering...</p>) : (<p>Register as Supervisor</p>) }
        </div>
    )
}

export default RegSup