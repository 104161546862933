import { useNavigate } from "react-router-dom";
import "./NewEmpBtn.css"
function NewEmpButton() {
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/newuser')
    }

    return <div className="NewSupButton textCenter" onClick={navigateTo} >New Employee Account</div>
}

export default NewEmpButton;