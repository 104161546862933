import { useState } from "react";
import "./FormLogin.css"
import app from "../firebaseUtils/firebaseUtils";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function FormLogin() {
    const auth = getAuth(app);
    const [flag, setFlag] = useState(false);
    const [credFlag, setCredFlag] = useState(false);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/passwordReset')
    }

    const signUp = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // console.log(user)
                alert("Successfully created and account")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                // const errorMessage = error.message;
                alert(errorCode)
                // ..
            });
    }
    const signIn = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                localStorage.setItem("User", JSON.stringify([email, password]));
                const user = userCredential.user;
                const uEmail = user.email;
                // console.log(user.email);
                alert("Successfully logged in")
                if (email === password) {
                    navigate("/passwordReset")
                }
                else {
                    
                    navigate('/content')
                }
                const user1 = auth.currentUser;

                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                if (String(errorCode) === "auth/wrong-password") { setFlag(true); }
                else if (String(errorCode) === "auth/user-not-found") { setCredFlag(true); }
                alert(errorCode);
            });

    }

    // const passReset = () => {
    //     return redirect("/passwordReset")
    // }


    return (
        <div className="form">

            <div className="textSecond">
                Supervisor Login
            </div>
            {/* Normal Login template*/}
            <div className="formInput">
                
                {credFlag && <div className="invalidCred" >Invalid Credentials!</div>}
                
                <section id="emailBlock">
                
                    <label htmlFor="emailInput" >Email
                    <input type={"email"} name="id_input" id="emailInput" required onChange={(e) => { setEmail(e.target.value) }} /></label>
                
                </section>
                
                <br />
                
                <section id="passBlock">
                
                    <label htmlFor="passInput" >Password
                    <input type="password" name="pass_input" id="passInput" required onChange={(e) => { setPassword(e.target.value) }} /></label>

                    {flag && <span className="forgotPass" id="forgotPass" onClick={navigateTo}>Forgot Password?</span>}
                
                </section>
                
                <br />
                
                <span className="forgotPass" onClick={navigateTo}>First Time User?</span>
            
            </div>

            {/* legend Login template*/}    

            {/* <div className="formInput">

                {credFlag && <div className="invalidCred" >Invalid Credentials!</div>}

                <fieldset id="emailBlock">
                
                    <legend htmlFor="emailInput" >Email</legend>
                    <input type={"email"} name="id_input" id="emailInput" required onChange={(e) => { setEmail(e.target.value) }} />
                
                </fieldset>
                
                <br />
                
                <fieldset id="passBlock">

                    <legend htmlFor="passInput" >Password</legend>
                    <input type="password" name="pass_input" id="passInput" required onChange={(e) => { setPassword(e.target.value) }} />
                    
                    {flag && <span className="forgotPass" id="forgotPass" onClick={navigateTo}>Forgot Password?</span>}
                
                </fieldset>
                
                <span className="forgotPass" onClick={navigateTo}>First Time User?</span>
            
            </div> */}








            {/* <table className="form_input flexer">
                <tbody id="rupaakId">

                    <tr>
                        {credFlag && <div className="invalidCred" >Invalid Credentials!</div>}

                    </tr>
                    <tr>
                        <td className="label">
                            <label htmlFor="id_input">Email</label>
                        </td>
                        <td id="rupaakEmail">

                            <input type={"email"} name="id_input" id="id_input" onChange={(e) => { setEmail(e.target.value) }} />

                        </td>
                    </tr>
                    <tr>
                        <td>

                            <label htmlFor="pass_input" className="label">Password</label>
                        </td>
                        <td>
                            <input type="password" name="pass_input" id="pass_input" onChange={(e) => { setPassword(e.target.value) }} /><br />
                            {flag && <span className="forgotPass" onClick={navigateTo}>Forgot Password?</span>}

                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span className="forgotPass" onClick={navigateTo}>First Time User?</span>
                        </td>
                    </tr>
                </tbody>
            </table> */}

            <div className="LoginButton" onClick={signIn}>Login</div>
        </div>
    )
}
export default FormLogin;